import React, {useContext, useEffect, useState} from "react";
import configuration from "../../Configuration";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {ListView, ListViewHeader, ListViewItemProps} from "@progress/kendo-react-listview";
import {Button} from "@progress/kendo-react-buttons";
import {useNavigate} from "react-router-dom";
import {TranscriptItem} from "../../models/TranscriptItem";
import CreateWindow from "./CreateWindow";

interface IState {
    lines: TranscriptItem[]
    isLoading?: boolean
}

const initialState: IState ={
    lines: [],
    isLoading: undefined
}

const ItemRender = (props: ListViewItemProps) => {
    const navigate = useNavigate();

    const editTranscript = (item: TranscriptItem) => {
        navigate(`/transcript?id=${item.meetingMinuteItemId}`);
    };

    const startRecording = (item: TranscriptItem) => {
        startUploadOrRecording("/record", item)

    }
    const startUpload = (item: TranscriptItem) => {
        startUploadOrRecording("/upload", item)
    }

    const startUploadOrRecording = (path: string, item: TranscriptItem) => {
        console.log(item)
        const pushState = {
            agendaId:item.agendaId, minuteItemId : item.meetingMinuteItemId
        };
        navigate(path, {state: pushState } );
    };

    let item = props.dataItem;
    return (
        <div
            className="k-listview-item row p-2 border-bottom align-middle"
            style={{margin: 0}}
        >
            <div className="col-6 text-start">{item.name}</div>
            <div className="col-2">{item.agendaDate}</div>
            {/*<div className="col-2">{item.postProcessingStatus === "finished" ? "transcribed" : "transcribing"}</div>*/}
            <div className="col-2">{item.postProcessingStatus}</div>
            <div className="col-2">{item.postProcessingStatus === "finished"
                ? (<Button onClick={(evt) => editTranscript(item)}>Proceed</Button>)
                : item.postProcessingStatus === "new"
                    ? (<>
                        <Button onClick={(evt) => startRecording(item)}>Record</Button>
                        <Button onClick={(evt) => startUpload(item)}>Upload</Button>
                    </>)
                    : (<></>)}</div>
        </div>
    );
}

const MyHeader = () => {

    return (
        <ListViewHeader
            style={{color: "rgb(160, 160, 160)", fontSize: 14}}
            className="pl-3 pb-2 pt-2"
        >
            <div
                className="k-listview-item row p-2 border-bottom align-middle"
                style={{margin: 0}}
            >
                <div className="col-6 text-start">Meeting Title</div>
                <div className="col-2">Meeting Date</div>
                <div className="col-2">Status</div>
            </div>

        </ListViewHeader>
    );
};

export const Transcripts = () => {
    const {token}: IAuthContext = useContext(AuthContext)
    const [createWindowVisible, setCreateWindowVisible] = React.useState<boolean>(false);
    const [state, updateState] = useState(initialState);

    useEffect(() => {
        const doStuff = async () => {
            if (state.isLoading == true) {
                return
            }
            await loadTranscripts();
        };

        if (state.isLoading === undefined) {
            updateState({...state, isLoading: true})
            doStuff().catch(console.error);
        }
    }, [state]);

    const loadTranscripts = async () => {
        const response = await fetch(
            `${configuration.baseApiUrl}/Transcript/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            const data = await response.json();
            let lines = data as TranscriptItem[];
            updateState({...state, isLoading: false, lines: lines})
        }
    };

    const toggleDialog = () => {
        setCreateWindowVisible(!createWindowVisible);
    };


    if (state.isLoading) {
        return (
            <>Loading </>
        );
    }

    return (
        <div style={{width: "75%", margin: "auto"}}>
            <div className="header k-d-flex">
                <div className="title"><h1>Transcriptions</h1></div>
                <span className="k-spacer"/>
                <div className="k-d-flex k-align-items-center">
                    <Button onClick={toggleDialog}>+ New Transcription</Button>
                </div>
            </div>
            <ListView data={state.lines}
                      item={ItemRender}
                      header={MyHeader}/>
            {createWindowVisible && ( <CreateWindow onClose={toggleDialog} /> )}
        </div>
    );
}

export default Transcripts;