import {useEffect, useRef, useState} from "react";

export const AudioRecorder = ({ deviceId, shouldStopRecording, onDataAvailable } : any) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaStream, setMediaStream] = useState<MediaStream>();
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
    const [currentDeviceId, setCurrentDeviceId] = useState<string>();
    const [audioContext, setAudioContext] = useState<AudioContext>();

    useEffect(() => {
        console.log("useEffect shouldStopRecording", shouldStopRecording, isRecording);
        if (shouldStopRecording) {
            stopRecorder()
            return;
        }
    }, [shouldStopRecording]);

    useEffect(() => {
        console.log("useEffect", deviceId, isRecording, shouldStopRecording);

        if (deviceId === currentDeviceId) {
            console.log("already initialized", deviceId)
            return;
        }

        if (deviceId && !isRecording) {
            setCurrentDeviceId(deviceId);
            setIsRecording(true);
            startRecorder().then();
        }
    }, [deviceId]);

    const startRecorder = async () => {
        console.log("start recorder", deviceId);
        if (mediaRecorder !== undefined) {
            console.log("recorder not null returning", deviceId);
            return;
        }

        const constraints = {
            video: false,
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                deviceId: deviceId ? {exact: deviceId} : undefined
            },
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        const permissionName = "microphone" as PermissionName
        const permission = await navigator.permissions.query({name: permissionName});

        if (permission.state !== "granted") {
            return;
        }

        // Chrome does not like mimeType, Safari didn't mind and works without it
        // const mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/mp4" });
        const recorder = new MediaRecorder(stream);

        // use five second chunks
        recorder.start(5 * 1000);

        recorder.ondataavailable = async (e) => {
            // console.log(e.data);
            // await this.uploadChunk(e.data);
            await onDataAvailable(e.data);
        }

        setMediaRecorder(recorder)
        setMediaStream(stream);
        setupVisualizer(stream);
    };

    const stopRecorder = () => {
        console.log("stop recorder", mediaRecorder, mediaStream, audioContext)

        if (mediaStream !== undefined && audioContext !== undefined) {
            const microphone = audioContext.createMediaStreamSource(mediaStream)
            microphone?.disconnect();
        }

        const tracks = mediaStream?.getTracks();
        tracks?.forEach(track=>{
            track.stop()
        })
        mediaRecorder?.stop();
        audioContext?.close().then();

        setIsRecording(false);
    }

    const setupVisualizer = (stream: MediaStream) => {
        console.log("setupVisualizer", stream);

        // https://github.com/mdn/dom-examples/blob/main/media/web-dictaphone/scripts/app.js
        // if(!state.audioCtx) {
        //     let audioCtx = new AudioContext();
        //     updateState({...state, audioCtx: audioCtx})
        // }
        if(audioContext !== undefined) {
            return;
        }
        const audioCtx = new AudioContext();

        const source = audioCtx.createMediaStreamSource(stream);

        const analyser = audioCtx.createAnalyser();
        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        source.connect(analyser);

        const canvas = canvasRef.current;
        if (canvas == null) {
            console.log("canvas == null")
            return
        }
        const canvasCtx = canvas.getContext('2d');
        if (canvasCtx == null) {
            console.log("canvasCtx == null")
            return
        }

        const draw = () => {
            // const canvasCtx = canvasCtx;
            const WIDTH = canvas.width
            const HEIGHT = canvas.height;

            // if (!shouldStopRecording) {
            //     requestAnimationFrame(draw);
            // } else {
            //     return;
            // }
            requestAnimationFrame(draw);

            analyser.getByteTimeDomainData(dataArray);

            // canvasCtx.fillStyle = 'rgb(200, 200, 200)';
            canvasCtx.fillStyle = 'rgb(255, 255, 255)';
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

            canvasCtx.beginPath();

            let sliceWidth = WIDTH * 1.0 / bufferLength;
            let x = 0;


            for(let i = 0; i < bufferLength; i++) {

                let v = dataArray[i] / 128.0;
                let y = v * HEIGHT/2;

                if(i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height/2);
            canvasCtx.stroke();
        }
        draw()

        setAudioContext(audioCtx);
    }

    return (
        <div>
            <canvas ref={canvasRef} style={{width: "100%", height: "100px", backgroundColor: "silver"}} ></canvas>
        </div>
    )
}