import React, {BaseSyntheticEvent, useEffect, useState} from "react";
import {Avatar} from "@progress/kendo-react-layout";
import {offset} from "@progress/kendo-popup-common";

type Props = {
    style:  React.CSSProperties | undefined;
    className?: string;
    value: number;
    markers: Marker[];
    onJump: (value: number) => void;
}

export type Marker = {
    initials: string,
    name: string,
    offset: number;
}

export const SpeakerProgressBar = ({ style,  value, markers, onJump, className } : Props) => {
    useEffect(() => {
        // console.log("markers pb", markers)
    }, [markers]);


    const onProgressClick = (event: any) => {
        // called when this container is clocked, als handles avatar clicks

        let currentTargetRect = event.currentTarget.getBoundingClientRect();
        const eventOffsetX = event.pageX - currentTargetRect.left;

        let value =  eventOffsetX / currentTargetRect.width * 100;

        onJump(value);
    }

    return (
        <div style={{...style, position: "relative", minHeight: "20px"}} onClick={onProgressClick} className={className}>
            <div style={{
                backgroundColor: "#888",
                width: "100%",
                height: "2px",
                position: "absolute",
                top: "50%",
                bottom: "50%",
                transform: "translate(0, -50%)"
            }}>
            </div>

            <div style={{
                backgroundColor: "#000",
                width: `${value ? value : 0}%`,
                height: "4px",
                position: "absolute",
                top: "50%",
                bottom: "50%",
                transform: "translate(0, -50%)"
            }}>
                {/*<span>{Math.floor(value)}% </span>*/}
            </div>

            {markers.map((value, index) => {
                return (
                    <Avatar key={index}
                            style={{
                                left: `${value.offset}%`,
                                top: "50%",
                                bottom: "50%",
                                display: "inline",
                                position: "absolute",
                                transform: "translate(0, -50%)"
                            }}
                            size={"medium"}>{value.initials}</Avatar>
                )
            })}
        </div>
    )
}

export default SpeakerProgressBar;