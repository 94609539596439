import {useLocation, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import configuration from "../../Configuration";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {Agenda, AgendaItem} from "../../models/Agenda";
import TopicsView from "./TopicsView";
import {Button} from "@progress/kendo-react-buttons";
import AudioSelection from "./AudioSelection";
import {AudioRecorder} from "./AudioRecorder";
import {BlobServiceClient} from "@azure/storage-blob";
import TranscriptApi from "../../infra/TranscriptApi";

export const RecordMeeting = () => {
    const {token}: IAuthContext = useContext(AuthContext)
    const location = useLocation();
    const navigate = useNavigate();

    const [agenda, setAgenda] = useState<Agenda | undefined>(undefined);
    const [currentAgendaItem, setCurrentAgendaItem] = useState<AgendaItem | undefined>(undefined);
    const [audioSettingsVisible, setAudioSettingsVisible] = React.useState<boolean>(true);
    const [audioDevice, setAudioDevice] = useState<string | undefined>(undefined);
    const [shouldStopRecording, setShouldStopRecording] = useState(false);
    const [minuteItemId, setMinuteItemId] = useState<string|undefined>();
    const [recordingInfo, setRecordingInfo] = useState<StartRecordingResponse | undefined>();

    useEffect(() => {
        if (location.state == null) {
            navigate("/");
            return;
        }

        let agendaId = location.state.agendaId;
        if (agenda === undefined && agendaId) {
            loadAgenda(agendaId).then();

        }
        let itemId = location.state.minuteItemId;
        if (itemId) {
            setMinuteItemId(itemId);
        }
    }, [ agenda ]);

    const loadAgenda = async (agendaId: string) => {
        console.log("loadAgenda", agendaId);
        const response = await fetch(
            `${configuration.baseApiUrl}/agenda/${agendaId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-iBabs-Device-Id': configuration.authenticationDeviceKey,
                    'Authorization': `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            const data = await response.json();
            const agenda = data as Agenda;
            setAgenda(agenda);
            // if (agenda.agendaItems && agenda.agendaItems.length > 0) {
            //     setCurrentAgendaItem(agenda.agendaItems[0]);
            // }
        } else {
            console.log("error", response.status)
        }
    };

    const toggleDialog = () => {
        setAudioSettingsVisible(!audioSettingsVisible);
    };

    const startRecording = async (audioDevice: string, audioLanguage: string) => {
        // save meeting item
        console.log("currentItemId", minuteItemId);
        let itemId = minuteItemId;
        if (minuteItemId === undefined) {
            itemId = await createItem(audioLanguage);
        } else {
            await updateItem(audioLanguage);
        }
        if (itemId === undefined) {
            console.log("No item id")
            return;
        }

        // start
        let ok = await startActualRecording(itemId);
        if (ok) {
            console.log("startRecording", audioDevice, audioLanguage);
            setAudioDevice(audioDevice);
            setAudioSettingsVisible(false);
        }
    }

    function finishRecording() {
        console.log("finishRecording current should stop", shouldStopRecording);
        setShouldStopRecording(true);
        stopRecording().then();
    }

    const createItem = async (language: string): Promise<string | undefined> => {
        let api = new TranscriptApi(token);
        let itemId = await api.createItem(language, location.state.agendaId);

        if (itemId !== undefined) {
            setMinuteItemId(itemId);
            return itemId;
        }
    }

    const updateItem = async (language: string): Promise<boolean> => {
        if (minuteItemId === undefined) {
            return false;
        }

        let api = new TranscriptApi(token);

        return await api.updateItem(minuteItemId, language);
    }

    const startActualRecording = async (itemId: string): Promise<boolean> => {
        const response = await fetch(
            `${configuration.baseApiUrl}/transcript/start/${itemId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-iBabs-Device-Id': configuration.authenticationDeviceKey,
                    'Authorization': `Bearer ${token}`
                },
            });

        if (response.status == 200) {
            let data = await response.json();
            let info = data as StartRecordingResponse;
            if (info != null) {
                setRecordingInfo(info);
                console.info(info)
            }
        }
        console.log(response.status);
        return response.status === 200;
    }

    const stopRecording = async (): Promise<boolean> => {
        if (minuteItemId === undefined) {
            return false;
        }

        let api = new TranscriptApi(token);

        return await api.stopRecording(minuteItemId,
            location.state.minimumSpeakers,
            location.state.maximumSpeakers);
    }

    const setNextTopic = () => {
        if (agenda?.agendaItems == null) {
            return
        }
        let index = -1;

        if (currentAgendaItem === undefined || currentAgendaItem == null) {
            index = 0;
        } else {
            let currentIndex = agenda.agendaItems?.indexOf(currentAgendaItem);
            if (currentIndex < 0) {
                return;
            }
            index = currentIndex + 1;
        }

        if (index >= agenda.agendaItems.length) {
            return;
        }
        setCurrentAgendaItem(agenda.agendaItems[index]);
    }

    const onDataAvailable = async (data: Blob) => {
        await uploadChunkAppend(data);
    }

    const uploadChunkAppend = async (chunk: Blob) => {
        if (recordingInfo === undefined || recordingInfo == null) {
            console.error("No recordingInfo", recordingInfo)
            return;
        }
        if (!recordingInfo.sasUrl || !recordingInfo.filename) {
            console.error("sasUrl or filename is null", recordingInfo)
            return;
        }

        const blobServiceClient = new BlobServiceClient(recordingInfo.sasUrl!);

        // sas url already have a container name, no need to supply it again
        // otherwise it will become a sub-directory
        const containerClient = blobServiceClient.getContainerClient("");

        console.log("Appending chunk", recordingInfo.filename, chunk);

        // upload chunk
        const appendClient = containerClient.getAppendBlobClient(recordingInfo.filename!);
        try {
            await appendClient.createIfNotExists();
        } catch (e) {
            // createIfNotExists throws an error for some reason, but works as intended
            // appendClient.exists() throws an unauthorized, so maybe related to that?
            console.log("appendClient.createIfNotExists", e)
        }

        try {
            await appendClient.appendBlock(chunk, chunk.size, {
                onProgress: (progress) => {
                    console.log("appending chunk", progress);
                }
            });
        } catch (e) {
            // createIfNotExists throws an error for some reason, but works as intended
            // appendClient.exists() throws an unauthorized, so maybe related to that?
            console.log("appendClient.appendBlock", e)
        }
    }

    if (agenda === undefined) {
        return (
            <>Loading agenda...</>
        )
    }

    return (
        <div style={{width: "75%", margin: "auto"}}>
            <div className="header k-d-flex">
                <div className="title">{agenda.title}</div>
                <span className="k-spacer"/>
                <div className="k-d-flex k-align-items-center py-2">
                    <Button onClick={finishRecording}>Finish Recording</Button>
                </div>
            </div>
            <TopicsView agenda={agenda} currentTopic={currentAgendaItem}/>
            {audioSettingsVisible && (<AudioSelection onClose={toggleDialog} onCallback={startRecording}/>)}

            <div style={ {position: "absolute", bottom: "0", width: "75%"} }>
                <div className="header k-d-flex">
                    <div className="title">{currentAgendaItem?.title ?? "Select topic?"}</div>
                    <span className="k-spacer"/>
                    <div className="k-d-flex k-align-items-center py-2">
                        <Button onClick={setNextTopic}>Next Subject</Button>
                    </div>
                </div>
                <div>
                    {audioDevice && <AudioRecorder deviceId={audioDevice}
                                                   shouldStopRecording={shouldStopRecording}
                                                   onDataAvailable={onDataAvailable}/>}
                </div>
            </div>
        </div>
    )
}

class StartRecordingResponse {
    sasUrl?: string;
    directoryName?: string;
    filenamePrefix?: string;
    filenameStartIndex?: number;
    filenameExtension?: string;
    filename?: string;
}



export default RecordMeeting;