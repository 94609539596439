import React, {useEffect, useRef, useState} from "react";
import {Button} from "@progress/kendo-react-buttons";
import {pauseIcon, playIcon} from "@progress/kendo-svg-icons";
import {Utils} from "../../util/Utils";
import SpeakerProgressBar from "./SpeakerProgressBar";
import {Speaker, TimelineEvent, TranscriptLine} from "./index";

export const AudioControl = ({src, style, timeline, onTimeChanged, ...props}: any) => {
    const audioControlRef = useRef(null);

    const [ duration, setDuration ] = useState(0);
    const [ currentTime, setCurrentTime ] = useState(0);
    const [ isPlaying, setPlaying ] = useState(false);
    const [ markers, setMarkers ] = useState<any[]>([])

    useEffect(() => {
        if (!audioControlRef?.current) {
            console.error("audioControlRef?.current null");
            return
        }
        let audio = audioControlRef.current as HTMLAudioElement;

        audio.addEventListener("loadedmetadata",onAudioadedMetaData);
        audio.addEventListener("timeupdate", onAudioTimeUpdate);

        return () => {
            audio.removeEventListener("loadedmetadata",onAudioadedMetaData);
            audio.removeEventListener("timeupdate", onAudioTimeUpdate);
        };
    }, [src]);

    const onAudioadedMetaData = (e: Event) => {
        if (!audioControlRef?.current) {
            console.error("audioControlRef?.current null");
            return
        }
        let audio = audioControlRef.current as HTMLAudioElement;
        setDuration(audio.duration)
    }

    const onAudioTimeUpdate = (e: Event) => {
        if (!audioControlRef?.current) {
            console.error("audioControlRef?.current null");
            return
        }
        let audio = audioControlRef.current as HTMLAudioElement;
        setCurrentTime(audio.currentTime);
        onTimeChanged?.(audio.currentTime);

    }

    useEffect(() => {

        let markers: any[] = [];

        let current = '';
        // let events = timeline as TimelineEvent[]
        // console.log("speakers timeline", events);

        // console.log("audio control timeline", timeline)

        // for (let e of events.sort(e => e.offset) ) {
        //     if (e.objectId === current) {
        //         continue;
        //     }
        //
        //     let initials = "";
        //     for (let i of e.text.split(" ")) {
        //         initials += i.charAt(0).toUpperCase();
        //     }
        //     markers.push({
        //         initials: initials,
        //         name: e.text,
        //         offset: e.offset / duration * 100
        //     })
        //
        //     current = e.objectId;
        // }

        // use transcript because that how it is shown
        for (let line of timeline as TranscriptLine[]) {
            if (current === line.speakerId) {
                continue;
            }

            let initials = "";
            for (let i of line.speakerLabel.split(" ")) {
                initials += i.charAt(0).toUpperCase();
            }
            markers.push({
                initials: initials,
                name: line.speakerLabel,
                offset: line.offsetSeconds / duration * 100
            })

            current = line.speakerId;
        }

        setMarkers(markers)
    }, [timeline, duration]);

    const handlePlay = () => {
        if (!audioControlRef?.current) {
            return
        }
        let audio = audioControlRef.current as HTMLAudioElement;
        audio.play();
        setPlaying(true);
    }

    const handlePause = () => {
        if (!audioControlRef?.current) {
            return
        }
        let audio = audioControlRef.current as HTMLAudioElement;
        audio.pause();
        setPlaying(false);
    }

    const onJump = (value: number) => {
        if (!audioControlRef?.current) {
            return
        }
        let audio = audioControlRef.current as HTMLAudioElement;
        audio.currentTime = value / 100 * duration;
    }

    return (
        <>
            <audio ref={audioControlRef} src={src} style={{width: "100%"}}></audio>
            <div style={style} className="k-footer k-d-flex align-items-center mb-4">
                {isPlaying
                    ? (<Button svgIcon={pauseIcon} onClick={handlePause}/>)
                    : (<Button svgIcon={playIcon} onClick={handlePlay}/>)}
                <SpeakerProgressBar style={{flexGrow: "1"}}
                                    className={"ms-3 me-3"}
                                    value={currentTime / duration * 100}
                                    markers={markers}
                                    onJump={onJump}/>
                <span className={"text-end"} style={{ width: "160px" }}>{Utils.secondsToTime(currentTime)} / {Utils.secondsToTime(duration)}</span>
            </div>
        </>
    )

};

export default AudioControl;