import React from 'react'
import {EditTranscript, RecordMeeting, Transcripts} from "./views";
import MediaFileUpload from "./views/Transcripts/MediaFileUpload";

const AppRoutes = [
    {
        index: true,
        element: <Transcripts />
    },
    {
        path: '/transcript',
        element: <EditTranscript />
    },
    {
        path: '/record',
        element: <RecordMeeting />
    },
    {
        path: '/upload',
        element: <MediaFileUpload />
    }
];

export default AppRoutes;
