import {AppBar, AppBarSection, AppBarSpacer, Avatar, Menu, MenuItem, MenuItemArrow} from "@progress/kendo-react-layout";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import React, {CSSProperties, useContext} from "react";
import {Button, DropDownButton} from "@progress/kendo-react-buttons";
import {Link} from "react-router-dom";

export const NavigationHeader = ({style, ...props}: any) => {
    const {tokenData, token, logOut, idToken, error, login}: IAuthContext = useContext(AuthContext)

    console.log(tokenData);
    let name = tokenData !== undefined ? tokenData.Fullname : "?";
    let initials = name.charAt(0).toUpperCase();
    let site = tokenData !== undefined ? tokenData["ibabs-site-name"] : "site?";

    let contact =  { avatar: initials, name: name, site: site };
    console.log(site)

    return (
        <AppBar style={style}>
            <AppBarSection>
                <Link to={"/"}>
                    <img id="logoImage" src="/iBabs_Logo.svg" style={{ height: "65px", padding: "8px 12px"}} alt="iBabs" />
                </Link>
            </AppBarSection>
            <AppBarSpacer />
            <AppBarSection>
                <span className="k-appbar-separator" />
            </AppBarSection>
            <AppBarSection>
                <div className="k-hbox">
                    <Avatar rounded="full" type="text" style={{marginRight: 5}}>
                        {contact.avatar}
                    </Avatar>
                    <div>
                        <h2
                            style={{
                                fontSize: "1.3em",
                                fontWeight: "normal",
                                margin: 0,
                            }}
                        >
                            {contact.name}
                        </h2>
                        {/*<p style={{margin: 0, fontSize: "0.8em"}}>*/}
                        {/*    {contact.site}*/}
                        {/*</p>*/}
                    </div>
                </div>
            </AppBarSection>
            <AppBarSection>
                <Button type='button' onClick={() => logOut()}>
                    Logout
                </Button>
            </AppBarSection>
        </AppBar>
    )
}