import React, {useContext} from 'react';
import {AuthContext, AuthProvider, IAuthContext, IAuthProvider} from "react-oauth2-code-pkce";
import { Button } from '@progress/kendo-react-buttons';

function LoginHelper( { children }: { children: React.ReactNode } ) : JSX.Element {
    const {tokenData, token, logOut, idToken, error, login}: IAuthContext = useContext(AuthContext)

    if (error) {
        return (
            <>
                <div style={{color: 'red'}}>An error occurred during authentication: {error}</div>
                <Button type='button' onClick={() => logOut()}>
                    Logout
                </Button>
            </>
        )
    }

    if (token) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        <>
            <p>Please login to continue</p>

            <Button type='button' style={{width: '100px'}} onClick={() => login()}>
                Login
            </Button>
        </>
    )
}

export function Authenticator({ authConfig, children }: IAuthProvider) : JSX.Element {
    return (
        <AuthProvider authConfig={authConfig}>
            <LoginHelper>
                {children}
            </LoginHelper>
        </AuthProvider>
    );
}