import {Speaker} from "./index";
import React from "react";
import {Avatar} from "@progress/kendo-react-layout";
import {Button} from "@progress/kendo-react-buttons";
import {pencilIcon} from "@progress/kendo-svg-icons";

type SpeakerItemProps = {
    speaker: Speaker,
    allowEdit?: boolean;
    onUpdateName?: (id: string, newName: string) => void;
}

export const SpeakerItem = ( {speaker, allowEdit, onUpdateName }: SpeakerItemProps ) => {
    let initials = "";
    for (let i of speaker.text.split(" ")) {
        initials += i.charAt(0).toUpperCase();
    }

    const handleClick = (e: any) => {
        let text = window.prompt("Update name", speaker.text);
        if (text && text.length) {
            onUpdateName?.(speaker.label, text);
        }
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    };

    return(
       <div className={"text-start w-auto px-0"}>
           <Avatar rounded="full" size={"medium"} type="text" style={{marginRight: 5}}>{initials}</Avatar>
           {speaker.text}
           {allowEdit === true &&
               <Button svgIcon={pencilIcon} onClick={handleClick} className={"float-end"} /> }
       </div>
   )
};

export default SpeakerItem;