import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import '@progress/kendo-theme-default/dist/all.css';
import '@progress/kendo-theme-material/dist/all.css';
import {Authenticator} from "./components/Authenticator";
import configuration from "./Configuration";
import {NavigationHeader} from "./components/NavigationHeader";
import {Route, Routes} from 'react-router-dom';
import AppRoutes from "./AppRoutes";


function App() {
  return (
      <div className="App">
          <Authenticator authConfig={configuration.authenticationConfig}>
            <>
                <NavigationHeader style={{marginBottom: "20px", width: "100%", position: "fixed", top: "0", zIndex: "1"}}></NavigationHeader>
                <div style={{marginTop: "100px", zIndex: "0"}}>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const {element, ...rest} = route;
                            return <Route key={index} {...rest} element={element}/>;
                        })}
                    </Routes>
                </div>
            </>
          </Authenticator>
      </div>
  );
}

export default App;
