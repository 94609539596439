import {DropDownList, DropDownListFilterChangeEvent, ListItemProps} from "@progress/kendo-react-dropdowns";
import React, {useEffect} from "react";
import {Speaker} from "./index";
import SpeakerItem from "./SpeakerItem";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns/DropDownList/DropDownListProps";
import {FilterDescriptor} from "@progress/kendo-react-dropdowns/common/filterDescriptor";

const valueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any
) => {
    if (!value) {
        return element;
    }

    const children = [
        <span key={value.speaker.label}>
            <SpeakerItem speaker={value.speaker} />
        </span>,
    ];

    return React.cloneElement(element, { ...element.props }, children);
};

const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
) => {
    const item = itemProps.dataItem as SpeakerProps;

    const itemChildren = (
        <div className={"w-100"} key={item.speaker.label}>
            <SpeakerItem speaker={item.speaker} onUpdateName={item.onUpdateName} allowEdit={true} />
        </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
};

type SpeakerSelectProps = {
    speakers: Speaker[];
    selectedSpeaker?: Speaker;
    onSelectSpeaker?: (speakerId: string) => void;
    onUpdateName?: (id: string, newName: string) => void;
}

type SpeakerProps = {
    speaker: Speaker;
    onUpdateName?: (id: string, newName: string) => void;
}

export const SpeakerSelect = ( { speakers, selectedSpeaker, onUpdateName, onSelectSpeaker } : SpeakerSelectProps ) => {
    const [allSpeakers, setSpeakers] = React.useState<SpeakerProps[]>([]);
    const [filteredSpeakers, setFilteredSpeakers] = React.useState<SpeakerProps[]>([]);
    let selected = { speaker: selectedSpeaker, onUpdateName: onUpdateName } as SpeakerProps;

    useEffect(() => {
        let items = speakers.map((s, i) => {
            return { speaker: s, onUpdateName: onUpdateName } as SpeakerProps;
        })

        let speaker = {
            speaker: { label: "-1", text: "(Add New)", start: 0, end: 0 },
            onUpdateName: onUpdateName
        } as SpeakerProps;
        items.push(speaker);
        setSpeakers(items)
        setFilteredSpeakers(items);
    }, [speakers]);

    const filterData = (filter: FilterDescriptor) => {
        return allSpeakers.filter((s) => {
            return s.speaker.text.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
        });
    };

    const filterChange = (event: DropDownListFilterChangeEvent) => {
        setFilteredSpeakers(filterData(event.filter));
    };

    const handleOnChange = (event: DropDownListChangeEvent) => {
        let value = event.target.value as SpeakerProps;
        onSelectSpeaker?.(value.speaker.label);
    };

    return (
        <>
            <DropDownList
                data={filteredSpeakers}
                itemRender={itemRender}
                valueRender={valueRender}
                defaultValue={selected}
                style={{ width: "400px"}}
                size={"small"}
                fillMode={"outline"}
                rounded={"small"}
                onChange={handleOnChange}
                filterable={true}
                onFilterChange={filterChange}
            />
        </>
    )
}

export default SpeakerSelect