import {TAuthConfig} from "react-oauth2-code-pkce";
import {Utils} from "./util/Utils";

const authenticationBaseUrl = "https://signon-test.ibabs.eu";

// development
// const baseApiUrl = "https://localhost:7037/api";
// const authenticationRedirectUrl = 'https://localhost:3000/';

// // deploy
const baseApiUrl = "https://ibabs-test-meeting-minutes-web-api.azurewebsites.net/api";
const authenticationRedirectUrl = 'https://ibabs-meeting-minutes-react.pages.dev/';

let deviceKey = Utils.getGetOrCreateKey("sU6yh6NfhSPu8ilH");

const authConfig: TAuthConfig = {
    clientId: '1488CC33-4559-447A-8610-B02CDBB0D747',
    authorizationEndpoint: `${authenticationBaseUrl}/OAuth/Authorize`,
    tokenEndpoint: `${authenticationBaseUrl}/OAuth/Token`,
    redirectUri: authenticationRedirectUrl ,
    extraAuthParameters: { "device_key": deviceKey},
    extraTokenParameters: { "device_key": deviceKey},
    onRefreshTokenExpire: (event) =>
        window.confirm('Tokens have expired. Refresh page to continue using the site?') && event.login(),
    // Example to redirect back to original path after login has completed
    preLogin: () => localStorage.setItem('preLoginPath', window.location.pathname),
    postLogin: () => window.location.replace(localStorage.getItem('preLoginPath') || ''),
    decodeToken: true,
    // scope: 'User.read',
    autoLogin: false,
}

export default {
    baseApiUrl: baseApiUrl,
    authenticationConfig: authConfig,
    authenticationDeviceKey: deviceKey,
};