import {URLHelpers} from "../../util/URLHelpers";
import React, {useContext, useEffect, useState} from "react";
import {TranscriptItem} from "../../models/TranscriptItem";
import configuration from "../../Configuration";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {Agenda} from "../../models/Agenda";
import {Utils} from "../../util/Utils";
import TranscriptListView from "./TranscriptListView";
import {Button} from "@progress/kendo-react-buttons";
import AudioControl from "./AudioControl";

export const EditTranscript = () => {
    const TICK: number = 10 * 1000 * 1000;

    const {token}: IAuthContext = useContext(AuthContext)
    const [transcript, updateTranscript] = useState<TranscriptEditItem  | undefined>(undefined);
    const [transcriptLines, updateTranscriptLines] = useState<TranscriptLine[]>([]);
    const [speakers, setSpeakers] = useState<Speakers>(new Speakers());
    const [currentTime, setCurrentTime] = useState(0);

    useEffect(() => {
        let transcriptId = URLHelpers.getQueryStringValue("id");
        console.log("trancriptId", transcriptId)
        if (transcriptId != null && transcript === undefined) {
            console.log("loadData", transcriptId);
            loadData(transcriptId).then();
        }
    }, [transcript, transcriptLines]);


    const loadData = async (transcriptId: string, reset: boolean = false) => {
        let loadedTranscript = await loadTranscript(transcriptId);
        if (loadedTranscript !== undefined) {
            updateTranscript(loadedTranscript)
        }

        let speakers: TimelineEvent[];

        let speakerLabels = new Speakers();
        if (!reset && loadedTranscript != null
            && loadedTranscript?.meetingMinuteItem?.timeLineEvents !== undefined
            && loadedTranscript?.meetingMinuteItem?.timeLineEvents.length) {
            // console.log("have speakers?", loadedTranscript?.meetingMinuteItem?.timeLineEvents)
            let events: TimelineEvent[] = [];
            for (let tle of loadedTranscript.meetingMinuteItem.timeLineEvents) {
                let e = new TimelineEvent();
                e.eventId = tle.timeLineEventId;
                e.objectId = tle.objectId;
                e.eventType = tle.eventType;
                e.text = tle.text
                e.offset = tle.offset;
                e.duration = tle.duration;
                e.data = tle.data;
                events.push(e);

                if (e.eventType === TimelineEventType.Speaker && !speakerLabels.contains(e.objectId)) {
                    speakerLabels.add(e.objectId, e.text);
                }
            }
            setSpeakers(speakerLabels);
            speakers = events.sort((a, b) => a.offset - b.offset);
        } else {
            // console.log("loadSpeakers")
            speakers = await loadSpeakers(transcriptId);

            if (loadedTranscript?.meetingMinuteItem !== undefined) {
                loadedTranscript.meetingMinuteItem.timeLineEvents = speakers;
            }
        }

        if (!reset && loadedTranscript?.transcript !== undefined
            && loadedTranscript?.transcript?.transcriptLines
            && loadedTranscript?.transcript?.transcriptLines.length) {

            // console.log("have transcript?", loadedTranscript?.transcript?.transcriptLines)
            let lines: TranscriptLine[] = [];
            for (let orig of loadedTranscript.transcript.transcriptLines) {
                let line = new TranscriptLine();
                line.Id = orig.transcriptLineId;
                line.DisplayText = orig.text;
                line.speakerId = orig.speakerId;
                line.Offset = orig.offsetTicks;
                line.Duration = orig.durationTicks;
                line.offsetSeconds = line.Offset / TICK;
                line.durationSeconds = line.Duration / TICK;
                line.speakerLabel = speakerLabels.getText(line.speakerId) ?? "Unknown";
                lines.push(line);
            }
            updateTranscriptLines(lines);
        } else {
            // console.log("loadTranscript")
            let transcriptLines = await loadTranscriptText(transcriptId);
            let converted = mapSpeakers(transcriptLines, speakers);
            updateTranscriptLines(converted);
        }
    }

    const loadTranscript = async (transcriptId: string): Promise<TranscriptEditItem | undefined> => {
        const response = await getData(`item/${transcriptId}`);

        if (response.status !== 200) {
            console.error(response)
            return
        }

        const data = await response.json();
        let item = data as TranscriptEditItem;
        item.transcriptId = transcriptId;
        // console.log(item);
        return item;
    }

    const loadTranscriptText = async (transcriptId: string): Promise<TranscriptLine[]> => {
        const response = await getData(`transcript/${transcriptId}`);

        if (response.status !== 200) {
            console.error(response);
            return [];
        }
        const data = await response.json();

        let transcriptLines = data as TranscriptLine[];
        for (let line of transcriptLines) {
            line.offsetSeconds = line.Offset /TICK;
            line.durationSeconds = line.Duration / TICK;
        }
        // console.log("loadTranscriptText transcriptLines", transcriptLines);
        return transcriptLines;
    };

    const loadSpeakers = async (transcriptId: string): Promise<TimelineEvent[]> => {
        const response = await getData(`speakers/${transcriptId}`);

        if (response.status !== 200) {
            console.error(response)
            return [];
        }
        const data = await response.json();
        const speakers = data as Speaker[];

        speakers.forEach(s => { s.text = s.label });
        let labels = speakers
            .map((v) => v.label)
            .filter((v, i, self) => self.indexOf(v) == i)
            .sort();

        let speakerLabels = new Speakers();
        for (let label of labels) {
            speakerLabels.add(label, label);
        }
        setSpeakers(speakerLabels);

        let timelineEvents: TimelineEvent[] = [];
        for (let speaker of speakers) {
            if (speaker.end === speaker.start) {
                continue;
            }
            let e = new TimelineEvent();
            e.eventId = Utils.uuid();
            e.objectId = speaker.label;
            e.eventType = TimelineEventType.Speaker;
            e.text = speakerLabels.getText(speaker.label) ?? speaker.label
            e.offset = speaker.start;
            e.duration = speaker.end - speaker.start;
            timelineEvents.push(e);
        }
        timelineEvents = timelineEvents.sort((a, b) => a.offset - b.offset);

        // console.log("timelineEvnets", timelineEvents);

        return timelineEvents;
    };

    const mapSpeakers = (transcriptLines: TranscriptLine[], speakers: TimelineEvent[]): TranscriptLine[] =>  {
        let converted: TranscriptLine[] = [];
        let index = 0;

        // this should at least show the whole transcript
        for (let line of transcriptLines) {
            let event = speakers.find(e => (
                line.offsetSeconds + line.durationSeconds > e.offset && line.offsetSeconds + line.durationSeconds < e.offset + e.duration
            ))
            // console.log(line.DisplayText, event);
            if (event != null) {
                line.speakerId = event.objectId;
                line.speakerLabel = event.text;
            } else if (index > 0) {
                line.speakerId = converted[index - 1].speakerId;
                line.speakerLabel = converted[index - 1].speakerLabel;
            }
            converted.push(line);
            index++;
        }

        return converted;
    };

    const onUpClick = (speakerId: string, index: number) => {
        console.log("Up", speakerId, index)
        if (index === 0) {
            return
        }

        transcriptLines[index - 1].speakerId = transcriptLines[index].speakerId;
        transcriptLines[index - 1].speakerLabel = transcriptLines[index].speakerLabel;

        updateTranscriptContext();
    };

    const onDOwnClick = (speakerId: string, index: number) => {
        console.log("Down", speakerId, index)
        if (index === 0) {
            return
        }

        transcriptLines[index].speakerId = transcriptLines[index - 1].speakerId;
        transcriptLines[index].speakerLabel = transcriptLines[index - 1].speakerLabel;

        updateTranscriptContext();
    };

    const onDeleteClick = (speakerId: string, index: number) => {
        console.log("Delete", speakerId, index)
        if (index === 0) {
            return
        }

        for (let i = index; i < transcriptLines.length; i++) {
            if (transcriptLines[i].speakerId && transcriptLines[i].speakerId !== speakerId ) {
                break;
            }

            transcriptLines[i].speakerId = transcriptLines[index - 1].speakerId;
            transcriptLines[i].speakerLabel = transcriptLines[index - 1].speakerLabel;
        }

        updateTranscriptContext();
    };

    const onChangeSpeaker = (speakerId: string, index: number) => {
        let label = speakers.getText(speakerId) ?? "not found";
        let currentSpeakerId = transcriptLines[index].speakerId;

        for (let i = index; i < transcriptLines.length; i++) {
            if (transcriptLines[i].speakerId !== currentSpeakerId ) {
                break;
            }

            transcriptLines[i].speakerId = speakerId
            transcriptLines[i].speakerLabel = label;
        }

        updateTranscriptContext();
    };

    const onUpdateName = (id: string, newName: string) => {
        let newSpeakers = new Speakers();

        for (let s of speakers.all()) {
            newSpeakers.add(s.label, s.text)
        }
        if (id === "-1") {
            newSpeakers.update(Utils.uuid(), newName);
        } else {
            newSpeakers.update(id, newName);
        }

        setSpeakers(newSpeakers);

        // update label in transcript
        for (let i = 0; i < transcriptLines.length; i++) {
            if (transcriptLines[i].speakerId === id ) {
                transcriptLines[i].speakerLabel = newName;
            }
        }
        updateTranscriptContext();
    };

    const onInsertSpeaker = (index: number) => {
        let speakerId = transcriptLines[index].speakerId;
        for (let i = index; i < transcriptLines.length; i++) {
            if (transcriptLines[i].speakerId && transcriptLines[i].speakerId !== speakerId ) {
                break;
            }

            transcriptLines[i].speakerId = "-1";
            transcriptLines[i].speakerLabel = "Not set";
        }

        updateTranscriptContext();
    }

    const onUpdateTranscriptLine = (index: number, text: string) => {
        transcriptLines[index].DisplayText = text;

        updateTranscriptContext();
    };

    const onDeleteTranscriptLine = (index: number) => {
        transcriptLines.splice(index, 1);

        updateTranscriptContext();
    };

    const updateTranscriptContext = () => {
        let lines = transcriptLines.map((v, i) => {
            return v;
        });
        updateTranscriptLines(lines);
    };


    const getData = async (path: string): Promise<Response> => {
        return await fetch(
            `${configuration.baseApiUrl}/TranscriptEdit/${path}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-iBabs-Device-Id': configuration.authenticationDeviceKey,
                    'Authorization': `Bearer ${token}`
                }
            });
    }

    const resetTranscript = async () => {
        let transcriptId = transcript?.transcriptId;
        if (transcriptId) {
            await loadData(transcriptId, true);
        }
    }

    const saveTranscript = async () => {
        let request = {
            TimelineEvents: timelineFromTranscript(),
            TranscriptLines: transcriptLines
        };

        // console.log(request);
        const response = await fetch(
            `${configuration.baseApiUrl}/TranscriptEdit/save/${transcript?.transcriptId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-iBabs-Device-Id': configuration.authenticationDeviceKey,
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(request)
            });

        // console.log(response.status);

    };

    const timelineFromTranscript = (): TimelineEvent[] => {
        let events: TimelineEvent[] = [];

        let speakerId = "";
        let offset = 0;
        let duration = 0;

        for (let line of transcriptLines) {
            if (line.speakerId === speakerId) {
                duration = line.offsetSeconds + line.durationSeconds;
                continue;
            }

            if (speakerId !== "") {
                let e = new TimelineEvent();
                e.eventId = Utils.uuid();
                e.objectId = speakerId;
                e.eventType = TimelineEventType.Speaker;
                e.text = speakers.getText(speakerId) ?? speakerId
                e.offset = offset;
                e.duration = duration;
                events.push(e);

            }
            offset = line.offsetSeconds;
            speakerId = line.speakerId
        }

        return events;
    }

    const onTimeChanged = (currentTime: number) => {
        console.log("onTimeChanged", currentTime);
        setCurrentTime(currentTime);
    }

    if (transcript === undefined) {
        return (
            <>Loading</>
        )
    }


    const localAgendaDate = (): string | undefined => {
        let agendaDate = transcript.agenda.date
        if (agendaDate === undefined) {
            return;
        }
        let date = Date.parse(agendaDate);

        if (isNaN(date)) {
            return;
        }

        const options: Intl.DateTimeFormatOptions  = {
            month: "short",
            year: "numeric",
            day: "numeric",
        };

        return new Date(date).toLocaleDateString(undefined, options);
    }

    return (
        <div style={{width: "75%", margin: "auto",}}>
            <div>
                <div className="header k-d-flex align-items-center mb-4">
                    <div className="title fs-3 fw-bold">{transcript.agenda?.title}</div>
                    <span className="k-spacer"/>
                    <div className="title fs-5 fw-light me-2">
                        {localAgendaDate()} {transcript.agenda?.startTime}-{transcript.agenda?.endTime}
                    </div>
                    <div className="k-d-flex k-align-items-center">
                        <Button onClick={saveTranscript}>Save Transcript</Button>
                        <Button onClick={resetTranscript}>Reset Transcript</Button>
                    </div>
                </div>

                <TranscriptListView transcript={transcriptLines}
                                    speakers={speakers}
                                    onUpClick={onUpClick}
                                    onDownClick={onDOwnClick}
                                    onDeleteClick={onDeleteClick}
                                    onChangeSpeaker={onChangeSpeaker}
                                    onUpdateName={onUpdateName}
                                    onInsertSpeaker={onInsertSpeaker}
                                    onUpdateTranscriptLine={onUpdateTranscriptLine}
                                    onDeleteTranscriptLine={onDeleteTranscriptLine}
                                    currentTime={currentTime} />
                <div style={{ height: "100px" }}>
                    {/*spacing for scroll and fixed/sticky AudioControl*/}
                </div>
            </div>

            <div style={{backgroundColor: "#fff", position: "fixed", bottom: "0", width: "75%", zIndex: "1000"}}>
                <AudioControl src={transcript.audioUrl} timeline={transcriptLines} onTimeChanged={onTimeChanged}/>
            </div>
        </div>
    )
}


class TranscriptEditItem {
    hasTimelineEvents: boolean;
    hasSpeechToText: boolean;
    hasSpeakers: boolean;
    audioUrl: string;
    agenda: Agenda;
    meetingMinuteItem: TranscriptItem | undefined;
    transcriptId: string | undefined;
    transcript: Transcript | undefined;
}

class Transcript {
    language: string;
    transcriptLines: any[];
}

enum TimelineEventType {
    Speaker = "speaker",
    Topic = "topic",
    Notice = "notice",
}

export class TimelineEvent {
    eventId: string;
    objectId: string;
    eventType: string;
    text: string;
    data: string;
    offset: number;
    duration: number;
}

export class Speakers {
    private mapping = new Map<string, string>();

    size(): number {
        return this.mapping.size;
    }

    keys(): IterableIterator<string> {
        return  this.mapping.keys();
    }

    values(): IterableIterator<string> {
        return  this.mapping.values();
    }

    add(label: string, text?: string) {
        if (label === undefined || text === undefined || label == null || text == null) {
            return
        }
        if (label === "" || text === "") {
            return;
        }
        if (!this.mapping.has(label)) {
            this.mapping.set(label, text || label);
        }
    }

    contains(label: string): boolean {
        return this.mapping.has(label);
    }

    update(label: string, text: string) {
        this.mapping.set(label, text);
    }

    getText(label: string): string | null | undefined{
        return this.mapping.get(label)
    }

    all() : any[] {
        let values:any[] = [];

        this.mapping.forEach((v, k) => {
            values.push({ label: k, text: v})

        })

        return values;
    }
}

export class Speaker {
    label: string;
    text: string;
    start: number;
    end: number;
}

export class TranscriptLine {
    Id: string;
    DisplayText: string;
    Offset: number;
    Duration: number;
    offsetSeconds: number;
    durationSeconds: number;
    NBest: NBest[]
    speakerId: string;
    speakerLabel: string;
}

class NBest {
    Confidence: number;
    Display: string;
    ITN: string;
    Lexical: string;
    MaskedITN: string;
    Words: STTWord[];
}

class STTWord {
    Confidence: number;
    Duration: number;
    Offset: number;
    Word: string;
}


export default EditTranscript;