import {ListView, ListViewItemProps} from "@progress/kendo-react-listview";
import {Speaker, Speakers, TranscriptLine} from "./index";
import {Utils} from "../../util/Utils";
import React, {useEffect, useRef, useState} from "react";
import {Button} from "@progress/kendo-react-buttons";
import {ButtonGroup} from "react-bootstrap";
import {arrowDownIcon, arrowUpIcon, pencilIcon, plusIcon, trashIcon} from "@progress/kendo-svg-icons";
import SpeakerSelect from "./SpeakerSelect";



const ItemRender = (props: ListViewItemProps) => {
    const [isHovering, setIsHovering] = useState(false);
    let groupItem = props.dataItem as Group;
    let items = groupItem.items as TranscriptLine[];
    let speaker: Speaker = groupItem.speaker;

    if (items === undefined) {
        console.log("items undefined?", props);
        return (<></>);
    }

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const onSelectSpeaker = (speakerId: string) => {
        groupItem.onChangeSpeaker?.(speakerId, groupItem.index);
    };

    const insertSpeaker = (index: number) => {
        groupItem.onInsertSpeaker?.(groupItem.index + index);
    }

    const deleteTranscriptLIne = (index: number) => {
        groupItem.onDeleteTranscriptLine?.(groupItem.index + index);
    };

    const editTranscriptLIne = (index: number) => {
        let text = prompt("Update text", items[index].DisplayText);
        if (text) {
            groupItem.onUpdateTranscriptLine?.(groupItem.index + index, text)
        }
    };

    return (
        <div>
            <div className="k-listview-item row p-2 border-bottom align-items-center"
                    style={{margin: 0}}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}>
                <SpeakerSelect speakers={groupItem.speakers}
                               selectedSpeaker={speaker}
                               onSelectSpeaker={onSelectSpeaker}
                               onUpdateName={groupItem.onUpdateName} />
                <div className={"fs-5 d-flex align-items-center w-auto"}>
                    {Utils.secondsToTime(groupItem.totalTime)}
                </div>
                {isHovering && (
                    <ButtonGroup className={"w-auto"}>
                        <Button svgIcon={arrowUpIcon} onClick={() => {
                            groupItem.onUpClick?.(speaker.label, groupItem.index)
                        }}/>
                        <Button svgIcon={arrowDownIcon} onClick={() => {
                            groupItem.onDownClick?.(speaker.label, groupItem.index)
                        }}/>
                        <Button svgIcon={trashIcon} onClick={() => {
                            groupItem.onDeleteClick?.(speaker.label, groupItem.index)
                        }}/>
                    </ButtonGroup>
                )}
            </div>
            {items.map((item, index) => {
                let textClass = item.offsetSeconds < groupItem.currentTime ? "text-body" : "text-black-50";

                if (groupItem.currentTime < item.offsetSeconds + item.durationSeconds && groupItem.currentTime > item.offsetSeconds) {
                    // use for scrolling, probably not the best method
                    textClass += " current-transcript-line";
                }

                return (
                    <div key={item.Id}
                         className="k-listview-item row p-2 border-bottom align-middle" style={{margin: 0}} >
                        <div className={"px-0 col-9 text-start " +  textClass} >{item.DisplayText}</div>
                        <div className="col align-self-end text-end">
                            <Button svgIcon={plusIcon} onClick={() => {
                                insertSpeaker(index)
                            }}/>
                            <Button svgIcon={pencilIcon} onClick={() => {
                                editTranscriptLIne(index)
                            }}/>
                            <Button svgIcon={trashIcon} onClick={() => {
                                deleteTranscriptLIne(index)
                            }}/>
                        </div>
                        {/*<div className={"col align-self-end text-end" +  textClass}> {Utils.secondsToTime(item.offsetSeconds)}</div>*/}
                        {/*<div className={"col align-self-end text-end" +  textClass}> {Utils.secondsToTime(item.durationSeconds)}</div>*/}
                    </div>
                );
            })}
        </div>
    );
}


type Group = {
    index: number;
    speaker: Speaker;
    speakers: Speaker[];
    onUpClick?: (speakerId: string, index: number) => void;
    onDownClick?: (speakerId: string, index: number) => void;
    onDeleteClick?: (speakerId: string, index: number) => void;
    onChangeSpeaker?: (speakerId: string, index: number) => void;
    onUpdateName?: (id: string, newName: string) => void;
    onInsertSpeaker?: (index: number) => void;
    onDeleteTranscriptLine?: (index: number) => void | undefined;
    onUpdateTranscriptLine?: (index: number, text: string) => void | undefined;
    totalTime: number,
    currentTime: number;
    items: TranscriptLine[];
}

type TranscriptListViewProps = {
    transcript: TranscriptLine[],
    onUpClick?: (speakerId: string, index: number) => void;
    onDownClick?: (speakerId: string, index: number) => void;
    onDeleteClick?: (speakerId: string, index: number) => void;
    onChangeSpeaker?: (speakerId: string, index: number) => void;
    onUpdateName?: (id: string, newName: string) => void;
    onInsertSpeaker?: (index: number) => void;
    onDeleteTranscriptLine?: (index: number) => void | undefined;
    onUpdateTranscriptLine?: (index: number, text: string) => void | undefined;
    speakers?: Speakers;
    currentTime: number;
}

export const TranscriptListView = (
    {
        transcript,
        onUpClick,
        onDownClick,
        onDeleteClick,
        onChangeSpeaker,
        onUpdateName,
        speakers,
        onInsertSpeaker,
        currentTime,
        onDeleteTranscriptLine,
        onUpdateTranscriptLine,
    }: TranscriptListViewProps) => {

    const [grouped, setGrouped] = useState<Group[]>([]);

    useEffect(() => {
        let currentId = ''
        let currentLabel = ''
        let lines: TranscriptLine[] = []
        let grouped: Group[] = []
        let seconds = 0;

        let convertedSpeakers: Speaker[] = speakers!.all().map( (s: any) => {
            if (s === undefined) {
                return { label: "U", text: "U", start: 0, end: 0 } as Speaker
            }
            return { label: s.label ?? "U", text: s.text ?? "U", start: 0, end: 0 } as Speaker
        });
        convertedSpeakers.sort((a: Speaker, b: Speaker) => {
            if (a.text > b.text) {
                return 1;
            }
            if (a.text < b.text) {
                return -1;
            }
            return 0;
        });

        let index = 0
        let startIndex = 0
        for (let line of transcript as TranscriptLine[]) {
            if (line.speakerId !== currentId) {
                let speaker: Speaker =  { label: currentId ?? "", text: currentLabel ?? "", start: 0, end: 0 };
                if (lines.length) {
                    grouped.push({
                        speaker: speaker,
                        speakers: convertedSpeakers,
                        index: startIndex,
                        totalTime: seconds,
                        currentTime: currentTime,
                        items: lines,
                        onUpClick: onUpClick,
                        onDownClick: onDownClick,
                        onDeleteClick: onDeleteClick,
                        onChangeSpeaker: onChangeSpeaker,
                        onUpdateName: onUpdateName,
                        onInsertSpeaker: onInsertSpeaker,
                        onUpdateTranscriptLine: onUpdateTranscriptLine,
                        onDeleteTranscriptLine: onDeleteTranscriptLine,
                    });
                    startIndex = index;
                }
                currentLabel = line.speakerLabel
                currentId = line.speakerId
                lines = []
                seconds = 0;
            }
            lines.push(line);
            seconds += line.durationSeconds;
            index++;
        }

        if (lines.length) {
            let speaker: Speaker =  { label: currentId ?? "", text: currentLabel ?? "", start: 0, end: 0 };
            grouped.push({
                speaker: speaker,
                speakers: convertedSpeakers,
                index: startIndex,
                totalTime: seconds,
                currentTime: currentTime,
                items: lines,
                onUpClick: onUpClick,
                onDownClick: onDownClick,
                onDeleteClick: onDeleteClick,
                onChangeSpeaker: onChangeSpeaker,
                onUpdateName: onUpdateName,
                onInsertSpeaker: onInsertSpeaker,
                onUpdateTranscriptLine: onUpdateTranscriptLine,
                onDeleteTranscriptLine: onDeleteTranscriptLine,
            });
        }

        setGrouped(grouped)
    }, [transcript, speakers, currentTime]);

    useEffect(() =>{
        // proboably not the best method
        let elems = document.getElementsByClassName("current-transcript-line");
        if (elems.length) {
            // console.log("scroll to", elems)
            elems[0].scrollIntoView({behavior: "smooth", block: "center"})
        }
    }, [grouped]);

    return (
        <ListView data={grouped} item={ItemRender} />
    )
};

export default TranscriptListView;