import {ListView, ListViewHeader, ListViewItemProps} from "@progress/kendo-react-listview";
import React, {useEffect, useState} from "react";
import {AgendaItem} from "../../models/Agenda";


export class Topic extends AgendaItem {
    isCurrentTopic: boolean = false;
    isHandled: boolean = false;
    isFutureTopic: boolean = false;
}

const MyHeader = () => {
    return (
        <ListViewHeader
            style={{color: "rgb(160, 160, 160)", fontSize: 14}}
            className="pl-3 pb-2 pt-2"
        >
            {/*<div className="header k-d-flex">*/}
            {/*    <div className="title"><h1>Programme</h1></div>*/}
            {/*</div>*/}
            <div
                className="k-listview-item row p-2 border-bottom align-middle"
                style={{margin: 0}}
            >
                <div className="col-6 text-start">Programme</div>
            </div>
        </ListViewHeader>
    );
}

const ItemRender = (props: ListViewItemProps) => {
    let item = props.dataItem as Topic;
    let textClass = item.isCurrentTopic ? "fw-bold" : "";

    if  (item.isFutureTopic) {
        textClass += " fw-lighter";
    }

    return (
        <div
            className="k-listview-item row p-2 border-bottom align-middle"
            style={{margin: 0}} >
            <div className={"col-6 text-start " + textClass }>{item.title}</div>
        </div>
    );
}

export const TopicsView = ({agenda, currentTopic} : any) => {
    const [agendaItems, updateAgendaItems] = useState<Topic[]>([])

    useEffect(() => {
        let topics: Topic[] = [];
        let foundTopic = false;

        for (let item of agenda.agendaItems) {
            let topic = item as Topic;
            topic.isCurrentTopic = false;
            topic.isFutureTopic = false;
            topic.isHandled = false;

            if (currentTopic !== undefined) {
                if (item.id === currentTopic.id) {
                    foundTopic = true;
                    topic.isCurrentTopic = true;
                }

                topic.isFutureTopic = foundTopic;
                if (!foundTopic) {
                    topic.isHandled = true;
                }
            } else {
                topic.isFutureTopic = true;
            }
            topics.push(topic);
        }
        updateAgendaItems(topics);

    }, [currentTopic]);

    return (
        <ListView data={agendaItems}
                  item={ItemRender}
                  header={MyHeader}/>
    );
}

export default TopicsView;