import React, {useEffect, useState} from "react";
import {Dialog} from "@progress/kendo-react-dialogs";
import {DropDownList, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {Button} from "@progress/kendo-react-buttons";
import ListItem from "../../models/ListItem";
import LanguageSelection from "../../components/LanguageSelection";

export const AudioSelection = ({onClose, onCallback, ...props}: any) => {
    const [audioDevices, setAudioDevices] = useState<ListItem[] | undefined>(undefined);
    const [audioDevice, setAudioDevice] = useState<string | undefined>(undefined);
    const [audioDLanguage, setAudioLanguage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (audioDevices === undefined) {
            setDeviceSelection().then();
        }
    }, [audioDevices]);

    const setDeviceSelection = async () => {
        // await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
        const constraints = {
            video: false,
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
            },
        }
        await navigator.mediaDevices.getUserMedia(constraints);

        // List cameras and microphones.
        const devices = await navigator.mediaDevices.enumerateDevices();
        let listItems: ListItem[] = [];
        devices.forEach((device, index) => {
            if (device.kind !== "audioinput") {
                return;
            }
            let label = device.label || `Device ${index + 1}`;
            let deviceId = device.deviceId || `${index}`;
            listItems.push(new ListItem(deviceId, label));
        })
        setAudioDevices(listItems);
    }

    function handleLanguageChange(languageCode: string) {
        setAudioLanguage(languageCode);
    }

    function handleDeviceChange(event: DropDownListChangeEvent) {
        let item = event.target.value as ListItem;
        setAudioDevice(item.id);
    }

    function handleClick() {
        onCallback(audioDevice, audioDLanguage);
    }

    return (
        <>
            <Dialog title={"Audio Settings"}
                    onClose={onClose}
                    width={300} height={300}>

                <div className="form-group">
                    <LanguageSelection onAudioLanguageChange={handleLanguageChange} />
                    <br/>
                    <label className="control-label">Audio Device</label><br/>
                    <DropDownList data={audioDevices}
                                  textField="description"
                                  dataItemKey="id"
                                  onChange={handleDeviceChange}/>
                    <br/>
                </div>
                <br/>
                <Button onClick={handleClick}>Start Recording</Button>
            </Dialog>
        </>
    )
}

export default AudioSelection;