import configuration from "../Configuration";

class TranscriptApi {
    private token: string

    constructor(token: string) {
        this.token = token;
    }

    async createItem(language: string, agendaId: string): Promise<string | undefined>
    {
        const postData = {
            agendaId: agendaId,
            AudioLanguage: language
        }
        const response = await fetch(
            `${configuration.baseApiUrl}/transcript`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-iBabs-Device-Id': configuration.authenticationDeviceKey,
                    'Authorization': `Bearer ${this.token}`
                },
                body: JSON.stringify(postData)
            });

        if (response.status === 201 || response.status === 200) {
            let itemId = await response.json();
            console.log(itemId)
            return itemId;
        }

        console.log(response.status);
    }
    async updateItem (minuteItemId: string, language: string): Promise<boolean> {
        const postData = {
            itemId: minuteItemId,
            AudioLanguage: language
        }

        const response = await fetch(
            `${configuration.baseApiUrl}/transcript/${minuteItemId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-iBabs-Device-Id': configuration.authenticationDeviceKey,
                    'Authorization': `Bearer ${this.token}`
                },
                body: JSON.stringify(postData)
            });

        console.log(response.status);
        return response.status === 200;
    }

    async startUpload (minuteItemId: string, filename: string): Promise<StartUploadResult|undefined> {
        const response = await fetch(
            `${configuration.baseApiUrl}/transcript/upload/${minuteItemId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-iBabs-Device-Id': configuration.authenticationDeviceKey,
                    'Authorization': `Bearer ${this.token}`
                },
                body: JSON.stringify(filename)
            });

        if (response.status === 201 || response.status === 200) {
            let result = await response.json() as StartUploadResult;
            console.log(result)
            return result;
        }

        console.log(response.status);
    }

    async stopRecording (itemId: string, minSpeakers: number, maxSpeakers: number): Promise<boolean>  {
        const postData = {
            MinNumberSpeakers: minSpeakers,
            MaxNumberSpeakers: maxSpeakers,
        };

        const response = await fetch(
            `${configuration.baseApiUrl}/transcript/stop/${itemId}`, {
                method: 'POST',
                body: JSON.stringify(postData),
                headers: {
                    'Content-Type': 'application/json',
                    'X-iBabs-Device-Id': configuration.authenticationDeviceKey,
                    'Authorization': `Bearer ${this.token}`
                },
            });

        console.log(response.status);
        return response.status === 200;
    }

}

export class StartUploadResult {
    sasUrl: string;
    containerName: string;
    filename: string;
}

export default TranscriptApi