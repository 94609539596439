import {DropDownList, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import React from "react";
import ListItem from "../models/ListItem";

export const LanguageSelection = ( { onAudioLanguageChange }: any) => {
    // languages currently available for transcription
    const languages = [
        { id: "ca-ES", description: "Catalan"},
        { id: "da-DK", description: "Danish (Denmark)"},
        { id: "nl-NL", description: "Dutch (Netherlands)"},
        { id: "en-AU", description: "English (Australia)"},
        { id: "en-CA", description: "English (Canada)"},
        { id: "en-IN", description: "English (India)"},
        { id: "en-NZ", description: "English (New Zealand)"},
        { id: "en-GB", description: "English (United Kingdom)"},
        { id: "en-US", description: "English (United States)"},
        { id: "fi-FI", description: "Finnish (Finland)"},
        { id: "fr-CA", description: "French (Canada)"},
        { id: "fr-FR", description: "French (France)"},
        { id: "de-DE", description: "German (Germany)"},
        { id: "it-IT", description: "Italian (Italy)"},
        { id: "pt-BR", description: "Portuguese (Brazil)"},
        { id: "pt-PT", description: "Portuguese (Portugal)"},
        { id: "es-MX", description: "Spanish (Mexico)"},
        { id: "es-ES", description: "Spanish (Spain)"},
        { id: "sv-SE", description: "Swedish (Sweden)"}
    ];

    function handleLanguageChange(event: DropDownListChangeEvent) {
        let item = event.target.value as ListItem;
        onAudioLanguageChange?.(item.id);
    }

    return (
        <>
            <label className="control-label">Audio Language</label><br/>
            <DropDownList data={languages}
                          textField="description"
                          dataItemKey="id"
                          onChange={handleLanguageChange}/>
        </>
    )
}

export default LanguageSelection;