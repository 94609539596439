export class Utils {

    static uuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // convert number of seconds to a hh:mm:ss.x string
    static secondsToTime(totalSeconds: number) : string {
        const pad = (num: number, size = 2) => ('000000000' + num).substr(-size);

        let hours = Math.floor(totalSeconds / 3600);
        let minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
        let seconds = Math.floor((totalSeconds % 60));
        let fraction = Math.ceil(((totalSeconds < 1.0) ? totalSeconds : (totalSeconds % Math.floor(totalSeconds))) * 10);

        return `${hours}:${pad(minutes)}:${pad(seconds)}.${fraction}`;
    }

    static getGetOrCreateKey(name: string): string {
        let deviceKey = localStorage.getItem(name)
        if (deviceKey) {
            return deviceKey;
        }

        deviceKey = this.generateKey()
        localStorage.setItem(name, deviceKey);

        return deviceKey;
    }

    static generateKey(): string {
        const array = new Uint8Array(32);
        window.crypto.getRandomValues(array);

        return  this.toBase64(array)
    }

    static toBase64(buffer: ArrayBuffer): string {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}
