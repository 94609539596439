import {Dialog} from "@progress/kendo-react-dialogs";
import configuration from "../../Configuration";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {useContext, useEffect, useState} from "react";
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns';
import {useNavigate} from "react-router-dom";
import {Button} from "@progress/kendo-react-buttons";
import ListItem from "../../models/ListItem";
import {NumericTextBox, TextBox} from "@progress/kendo-react-inputs";
import {
    NumericTextBoxChangeEvent
} from "@progress/kendo-react-inputs/numerictextbox/interfaces/NumericTextBoxChangeEvent";


class State {
    public agendaTypes: ListItem[] | undefined
    public agendas: ListItem[] | undefined
    public selectedAgendaId: string | undefined
    public minimumSpeakers: number = -1;
    public maximumSpeakers: number = -1;
}

export const CreateWindow = ({onClose, ...props}: any) => {
    const {token}: IAuthContext = useContext(AuthContext)
    const [state, updateState] = useState<State>(new State());
    const navigate = useNavigate();

    useEffect(() => {
        if (state.agendaTypes === undefined) {
            loadAgendaTypes().then();
        }
    }, [state]);

    const loadAgendaTypes = async () => {
        const response = await fetch(
            `${configuration.baseApiUrl}/agenda/agendatypes`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-iBabs-Device-Id': configuration.authenticationDeviceKey,
                    'Authorization': `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            const data = await response.json();

            let items = [];
            for (let t of data) {
                items.push(new ListItem(t.id, t.description));
            }
            updateState({...state, agendaTypes: items});
        }
    }

    const handleAgendaTypeChange = (event: DropDownListChangeEvent) => {
        let agendaType = event.target.value as ListItem;
        if (agendaType !== undefined) {
            loadAgendas(agendaType.id).then();
        }
    };

    const loadAgendas = async (agendaTypeId: string) => {
        const response = await fetch(
            `${configuration.baseApiUrl}/agenda/agendas/${agendaTypeId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-iBabs-Device-Id': configuration.authenticationDeviceKey,
                    'Authorization': `Bearer ${token}`
                }
            });

        if (response.status === 200) {
            const data = await response.json();

            let items = [];
            for (let t of data) {
                let text = `${t.title} ${t.dateString}`;
                items.push(new ListItem(t.id, text));
            }
            updateState({...state, agendas: items});
        }
    }

    const handleAgendaChange = (event: DropDownListChangeEvent) => {
        let agendaType = event.target.value as ListItem;
        updateState({...state, selectedAgendaId: agendaType.id });
    }

    const handleCreateClick = ()=>{
        gotoNextStep("/record")
    }

    const handleUploadClick = ()=> {
        gotoNextStep("/upload")
    }


    const gotoNextStep = (path: string) => {
        if (state.selectedAgendaId !== undefined) {
            const pushState = {
                agendaId: state.selectedAgendaId,
                minimumSpeakers: state.minimumSpeakers,
                maximumSpeakers: state.maximumSpeakers,
            };
            navigate(path, {state: pushState } );
        }
    }

    const minSpeakerChange = (e: NumericTextBoxChangeEvent) => {
        if (e.target.value) {
            updateState({...state, minimumSpeakers: e.target.value });
        }
    };

    const maxSpeakerChange = (e: NumericTextBoxChangeEvent) => {
        if (e.target.value) {
            updateState({...state, maximumSpeakers: e.target.value });
        }
    };

    return (
        <>
            <Dialog title={"Transcription Settings"}
                    onClose={onClose}
                    width={800} height={600}>
                <div>Select a meeting</div>

                <div className="form-group">
                    <label className="control-label">Select agenda type</label><br/>
                    <DropDownList data={state.agendaTypes}
                                  textField="description"
                                  dataItemKey="id"
                                  style={{width: "50%"}}
                                  onChange={handleAgendaTypeChange}/>
                </div>
                <div className="form-group">
                    <label className="control-label">Select agenda</label><br/>
                    <DropDownList data={state.agendas}
                                  textField="description"
                                  dataItemKey="id"
                                  style={{width: "50%"}}
                                  onChange={handleAgendaChange}/>
                </div>
                <div className="form-group">
                    <label className="control-label">Minimum number of speakers</label><br/>
                    <NumericTextBox defaultValue={state.minimumSpeakers}
                                    onChange={minSpeakerChange}
                                    style={{width: "50%"}} />
                </div>
                <div className="form-group">
                    <label className="control-label">Maximum number of speakers</label><br/>
                    <NumericTextBox defaultValue={state.maximumSpeakers}
                                    onChange={maxSpeakerChange}
                                    style={{width: "50%"}} />
                </div>
                <br/>
                <Button onClick={handleCreateClick}>New recording</Button>
                <Button onClick={handleUploadClick}>Upload recording</Button>
            </Dialog>
        </>
    )
};

export default CreateWindow;