import {Dialog} from "@progress/kendo-react-dialogs";
import React, {useContext, useState} from "react";
import LanguageSelection from "../../components/LanguageSelection";
import {Button} from "@progress/kendo-react-buttons";
import {Upload, UploadFileInfo, UploadFileStatus} from "@progress/kendo-react-upload";
import {useLocation, useNavigate} from "react-router-dom";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import TranscriptApi, {StartUploadResult} from "../../infra/TranscriptApi";
import {BlobServiceClient} from "@azure/storage-blob";

export const MediaFileUpload = ({onClose, ...props}: any) => {
    const {token}: IAuthContext = useContext(AuthContext)
    const location = useLocation();
    const navigate = useNavigate();

    // const [minuteItemId, setMinuteItemId] = useState<string|undefined>();

    const [audioLanguage, setAudioLanguage] = useState<string | undefined>(undefined);
    // const requestIntervalRef = React.useRef({});
    // const progressRef = React.useRef({});

    const onCloseDialog = () => {
        navigate("/");
    };

    function handleLanguageChange(languageCode: string) {
        setAudioLanguage(languageCode);
    }

    const onSaveRequest = async (files: UploadFileInfo[],
                           options: { formData: FormData; requestOptions: any; },
                           onProgress: (uid: string, event: ProgressEvent) => void): Promise<{ uid: string; }> => {

        const currentFile = files[0] as UploadFileInfo;
        const uid = currentFile.uid;


        return new Promise<{ uid: string }>((resolve, reject) => {
            if ( currentFile.validationErrors && currentFile.validationErrors.length > 0 ) {
                reject({ uid: uid });
            } else {
                let stuff = async () => {
                    if (currentFile === undefined) {
                        console.error("error currentFile")
                        return;
                    }
                    let file = currentFile.getRawFile?.();
                    if (file === undefined) {
                        console.error("error currentFile raw file")
                        return;
                    }
                    let itemId = await upsertItem();
                    if (itemId === undefined) {
                        console.error("error saving item")
                        return;
                    }
                    let urlInfo = await getStorageUrl(itemId, currentFile.name);
                    if (urlInfo === undefined) {
                        console.error("error getting sas url")
                        return;
                    }

                    let progress = (loaded: number, total: number) => {
                        let e: ProgressEvent = {
                            lengthComputable: true, loaded: loaded, total: total
                        } as ProgressEvent;
                        onProgress?.(uid, e);
                    }

                    await uploadFile(file, urlInfo, progress);

                    await finishUpload(itemId);

                    onCloseDialog();
                }
                console.log("stuff")
                stuff()
            }
        });
    };


    const upsertItem = async (): Promise<string|undefined> => {
        let agendaId = location.state.agendaId;
        let itemId = location.state.minuteItemId;
        console.log(agendaId, itemId, audioLanguage);
        if (!agendaId || !audioLanguage || audioLanguage === undefined) {
            return
        }

        let api = new TranscriptApi(token);
        if (!itemId) {
            itemId = await api.createItem(audioLanguage, agendaId);
        } else {
            await api.updateItem(itemId, audioLanguage);
        }

        return itemId;
    }

    const getStorageUrl = async (itemId: string, filename: string): Promise<StartUploadResult|undefined> => {
        let api = new TranscriptApi(token);

        return await api.startUpload(itemId, filename);
    }

    const uploadFile = async (file: File,
                              uploadInfo: StartUploadResult,
                              onProgress: (loaded: number, total: number) => void) :Promise<boolean> => {
        const blobServiceClient = new BlobServiceClient(uploadInfo.sasUrl);

        // sas url already have a container name, no need to supply it again
        // otherwise it will become a sub-directory
        const containerClient = blobServiceClient.getContainerClient("");

        // use a generic filename
        const blockBlobClient = containerClient.getBlockBlobClient(uploadInfo.filename);

        // upload the file
        const response = await blockBlobClient.uploadData(file, {
            onProgress: (progress) => {
                onProgress(progress.loadedBytes, file.size);
            }
        });

        return true;
    }

    const finishUpload = async (itemId: string) => {
        let api = new TranscriptApi(token);

        return await api.stopRecording(itemId,
            location.state.minimumSpeakers,
            location.state.maximumSpeakers);
    }

    return (
        <>
            <Dialog title={"Upload file"}
                    onClose={onCloseDialog}
                    width={600} height={500}>

                <div className="form-group">
                    <LanguageSelection onAudioLanguageChange={handleLanguageChange}/>
                    <br/>
                    <br/>
                    <Upload
                        batch={false}
                        multiple={false}
                        defaultFiles={[]}
                        autoUpload={false}
                        saveUrl={onSaveRequest}
                        // onCancel={onCloseDialog}
                        withCredentials={false} // enable only for CORS
                        restrictions={{allowedExtensions: [".mp4", ",mov", ".wav", ".m4a", ".mp3"]}}
                    />
                    <br/>
                </div>
                <br/>
                <Button onClick={onCloseDialog} className={"float-end"}>Cancel</Button>
            </Dialog>
        </>
    )
}
export default MediaFileUpload;
